//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'app-home-header',
  props: [
    'direction',
    'src',
    'subTitle',
    'title',
    'desc',
    'buttonContent',
  ],
};
