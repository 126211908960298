import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/database';


// import { firestoreAction } from 'vuexfire';

export default {
  namespaced: true,

  state: {
    loading: false,
    images: [],
    tempImages: [],
    finalImages: [],
    lastVisible: null,
    next: [],
    index: 1,
  },

  getters: {
    loading: (state) => state.loading,
    images: (state) => state.images,
    finalImages: (state) => state.finalImages,
    tempImages: (state) => state.tempImages,
    lastVisible: (state) => state.lastVisible,
    next: (state) => state.next,
    index: (state) => state.index,
  },

  mutations: {
    FETCH_STARTED(state) {
      state.loading = true;
    },
    FETCH_FINISHED(state) {
      state.loading = false;
      state.index++;
    },
    PUSH_IMAGE(state, data) {
      for (const val of Object.values(data)) {
        state.images.push(val)
        // use val
    }
      // state.images.push(data);
    },
    PUSH_TO_IMAGES(state, payload) {
      // state.tempImages[0].images.forEach((element) => {
      payload.images.forEach((image) => {
        state.finalImages.push(image);
      });

      // });
    },
    SET_LAST_VISIBLE(state, payload) {
      state.lastVisible = payload._doc;
    },
    SET_NEXT(state, payload) {
      state.next = payload;
    },
  },

  actions: {
    // putImageInFirestore: ({ getters, commit }, url) => {
    //   commit('PUSH_IMAGE', url);
    //   if (getters.images.length % 10 == 0) {
    //     var id = firebase
    //       .firestore()
    //       .collection('ids')
    //       .doc().id;

    //     firebase
    //       .firestore()
    //       .collection('gallery')
    //       .doc(id)
    //       .set({
    //         id: id,
    //         images: getters.images.slice(((getters.images.length / 10) - 1) * 10),
    //       });
    //   }
    // },

    async doFetchImages({ commit,getters }) {
      commit('FETCH_STARTED');
      var ref = firebase.database().ref(`gallery/${getters.index}`)
      await ref.on('value',(snapshot) => {
        var data = snapshot.val()
        commit('PUSH_IMAGE',data)
        commit('FETCH_FINISHED');
      })
      // var storageRef = firebase.storage().ref(`gallery/gallery-${getters.index}`);
      // storageRef
      //   .listAll()
      //   .then(function(result) {
      //     result.items.forEach(async function(imageRef) {
      //       imageRef.getDownloadURL().then(function(url) {
      //         commit('PUSH_IMAGE',url)
      //         // dispatch('putImageInFirestore', url);
      //       });
      //     });
      //   })

      // .catch(function(error) {
      // Handle any errors
      // });
    },

    // doFetchImages: firestoreAction(
    //   async ({ commit, getters }) => {
    //     commit('FETCH_STARTED');
    //     var query = firebase
    //       .firestore()
    //       .collection('gallery')
    //       .limit(1)
    //       .orderBy('id', 'desc');
    //     if (getters.lastVisible != null) {
    //       query = query.startAfter(getters.lastVisible);
    //     }
    //     query = await query.get();
    //     query.docs.forEach((doc) => {
    //       let array = doc.data();
    //       array['_doc'] = doc;
    //       commit('SET_LAST_VISIBLE', array);
    //       commit('PUSH_TO_IMAGES', array);
    //     });
    //     var next = await firebase
    //       .firestore()
    //       .collection('gallery')
    //       .limit(1)
    //       .orderBy('id', 'desc')
    //       .startAfter(getters.lastVisible)
    //       .get();

    //     next.docs.forEach((doc) => {
    //       let array = doc.data();
    //       array['_doc'] = doc;
    //       commit('SET_NEXT', array);
    //     });

    //     commit('FETCH_FINISHED');
    //   },
    // ),
  },
};
