import { setLanguageCode } from '@/i18n';

export class I18nUtil {
  static doChangeLanguage(language) {
    let hostname = location.hostname;
    let port = location.port;
    console.log(port);

    //let protocol = window.location.protocol
    let host = window.location.host // js-tricks.info
    //let search = window.location.search // ?s=flexbox
    //let pathname = window.location.pathname // Returns path only : "check-path-example"
    let url = window.location.href // Returns full URL : 'https://js-tricks.info/check-path-example'


    // debugger

    if (hostname.includes('localhost')) {
      setLanguageCode(language);
      document.location.reload(false);
      return
    } 

    if (language == 'en') {
      window.location = url.replace('ar.', '')
      return
    }

    if (language == 'ar') {
      host = host.replace('www.', '')
      window.location = url.replace(host, `ar.${host}`)
      return
    }
  }
}
