import { i18n } from '@/vueI18n';
import Layout from '@/modules/layout/components/layout';

const SamaElBaraka = () =>
  import(
    '@/modules/partner/components/sama-el-baraka/home.vue'
  );
const RFTIC = () =>
  import('@/modules/partner/components/RFTIC/home.vue');
const Lama = () =>
  import('@/modules/partner/components/lama/home.vue');
const SamaElBarakaImport = () =>
  import(
    '@/modules/partner/components/sama-el-baraka-import/home.vue'
  );
const FalconWaterPro6 = () =>
  import(
    '@/modules/partner/components/sama-el-baraka/falcon-water-pro-6.vue'
  );

export default [
  {
    path: '/:lang?',
    exact: true,
    component: Layout,
    children: [
      {
        name: 'sama-el-baraka',
        path: '/:lang?/sama-el-baraka',
        exact: true,
        component: SamaElBaraka,
        beforeEnter: (to, from, next) => {
          document.title = i18n('app.samaElBaraka');
          next();
        },
      },
    ],
  },
  {
    name: 'sama-el-baraka-product',
    path: '/:lang?/sama-el-baraka/falcon-water-pro-6',
    exact: true,
    component: FalconWaterPro6,
    beforeEnter: (to, from, next) => {
      document.title = i18n('app.samaElBaraka');
      next();
    },
  },
  {
    name: 'rftic',
    path: '/:lang?/rftic',
    exact: true,
    component: RFTIC,
    beforeEnter: (to, from, next) => {
      document.title = i18n('app.rftic');
      next();
    },
  },
  {
    name: 'lama',
    path: '/:lang?/lama',
    exact: true,
    component: Lama,
    beforeEnter: (to, from, next) => {
      document.title = i18n('app.lama');
      next();
    },
  },
  {
    name: 'sama-el-baraka-import',
    path: '/:lang?/sama-el-baraka-import-export',
    exact: true,
    component: SamaElBarakaImport,
    beforeEnter: (to, from, next) => {
      document.title = i18n('app.sama-el-baraka-import');
      next();
    },
  },
  // {
  //   name:"water-filter",
  //   path: '/:lang?/water-filter',
  //   exact: true,
  //   component: WaterFilter
  // },
];
