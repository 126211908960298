import Layout from '@/modules/layout/components/layout';
import {i18n} from '@/vueI18n'
const HomePage = () =>
  import('@/modules/home/components/home-page.vue');

export default [
  {
    path: '/:lang?',
    exact: true,
    component: Layout,
    children: [
      {
        name: 'home',
        path: '/',
        component: HomePage,
        exact: true,
        beforeEnter: (to, from, next) => {
          document.title =
            i18n('app.title') +
            ' | ' +
            i18n('app.home') 
          next();
        },
        // meta: { unauth: true },
      }
    ],
  },
];
