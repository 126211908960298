//
//
//
//
//
//
//
//
//
//
//
//
//

import i18n from '@/vueI18n';
import { Quasar } from 'quasar';

export default {
  name: 'app-i18n-flags',

  data() {
    return {
      ar: {
        label: 'English',
        id: 'en',
        flag: 'https://firebasestorage.googleapis.com/v0/b/afigr-12814.appspot.com/o/flags%2Fenglish.svg?alt=media&token=3a15c3b4-32be-4f76-9460-ef1b55a5ab59',
      },
      en: {
        label: 'Arabic',
        id: 'ar',
        flag: 'https://firebasestorage.googleapis.com/v0/b/afigr-12814.appspot.com/o/flags%2Farabic.svg?alt=media&token=41b159d0-13e4-4b1d-bc07-a9f268b384e3',
      },
    };
  },
  computed: {
    currentLang() {
      return i18n.locale;
    },
  },

  methods: {
    doChangeLanguage(language) {
      i18n.locale = language;
      localStorage.setItem('language', language);
      let route = '';

      if (language === 'ar') {
        import(`quasar/lang/ar`).then((lang) => {
          // eslint-disable-next-line
          Quasar.lang.set(lang.default);
        });
      } else {
        import(`quasar/lang/en-us`).then((lang) => {
          // eslint-disable-next-line
          Quasar.lang.set(lang.default);
        });
      }

      route = {
        name: this.$route.name,
        params: { lang: i18n.locale },
      };

      this.$router.push(route);
      // console.log(this.isArabic);
    },
    // doChangeLanguage(language) {
    //   I18nUtil.doChangeLanguage(language);
    // },
  },
};
