//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  data() {
    return {
      logo: require('../../../assets/logo.svg'),
      socialList: [
        {
          icon: 'fa-facebook-f',
          url: 'https://www.facebook.com/pages/category/Office-Supplies/Al-Faisal-International-Group-395497224370647/',
        },
      ],
    };
  },
};
