//
//
//
//
//
//
//
//
//
//
//
//
//
//

import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import i18n from '@/vueI18n';

export default {
  name: 'MapContainer',
  props: ['lat', 'lng', 'marker', 'productPage', 'divId'],

  data() {
    return {};
  },
  computed: {
    center() {
      return {
        lat: parseFloat(this.lat),
        lng: parseFloat(this.lng),
      };
    },
    language() {
      return i18n.locale;
    },
  },
  watch: {},
  methods: {
    mountLinkUrl(markers) {
      for (var index = 0; index < markers.length; index++) {
        markers[index].addEventListener('click', (el) => {
          var markerText = el.path[0].childNodes[0].data;
          var clickedMarker = this.marker.filter(
            (element) => {
              return (
                element.nameEn.replace(/\s+/g, '') ==
                markerText.replace(/\s+/g, '')
              );
            },
          );
          if (clickedMarker[0]) {
            window
              .open(clickedMarker[0].url, '_blank')
              .focus();
          }
        });
      }
    },
  },
  async mounted() {
    this.map = await L.map(this.divId).setView(
      [this.lat, this.lng],
      14,
    );
    const myIcon = L.icon({
      iconUrl: '/images/map-marker.png',
    });
    await L.tileLayer(
      'https://{s}.tile.jawg.io/jawg-dark/{z}/{x}/{y}{r}.png?access-token=PweAEN2s3uErOlckjamGLU7iFsMbvt6HcCvBExSTVOyw5U4kqtVeOTNJ45jAYq63',
    ).addTo(this.map);
    this.marker.forEach((element) => {
      L.marker([element.lat, element.lng], {
        icon: myIcon,
      })
        .addTo(this.map)
        .bindPopup(
          this.language == 'en'
            ? element.nameEn
            : element.nameAr,
        )
        .openPopup();
    });
    var markers = document.getElementsByClassName(
      'leaflet-popup-content',
    );
    for (let index = 0; index < markers.length; index++) {
      const element = markers[index];
      element.classList.add('cursor-pointer');
    }
    this.mountLinkUrl(markers);
  },
  beforeDestroy() {
    if (this.map) {
      this.map.remove();
    }
  },
};
