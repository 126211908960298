//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import I18nFlags from '../../../shared/i18n/i18n-flags.vue';
import { Icon } from '@iconify/vue2';
import i18n from '@/vueI18n';

export default {
  data: () => ({
    windowTop: 0,
    drawer: false,
    itemsScroll: [
      { title: 'header.home', id: '#home' },
      { title: 'header.ourCompanies', id: '#ourCompanies' },
      { title: 'header.about', id: '#about' },
      { title: 'header.contact', id: '#contact' },
    ],
    itemsLink: [
      { title: 'header.gallery', to: 'gallery' },
      { title: 'header.videos', to: 'videos' },
    ],
  }),
  components: {
    I18nFlags,
    Icon,
  },
  computed: {
    position() {
      return document.documentElement.scrollTop;
    },
    language() {
      return i18n.locale;
    },
  },
  mounted() {
    window.addEventListener('scroll', this.onScroll, true);
  },
  beforeDestroy() {
    window.removeEventListener(
      'scroll',
      this.onScroll,
      true,
    );
  },
  methods: {
    onScroll() {
      this.windowTop =
        window.top.scrollY; /* or: e.target.documentElement.scrollTop */
    },
    close() {
      this.drawer = false;
    },
    open() {
      this.drawer = true;
    },
  },
  watch: {},
};
