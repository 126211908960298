const firebaseConfig = {
  apiKey: "AIzaSyAGGvM9ooAMwnJPdH6MX_tuN0XPKV-vleQ",
  authDomain: "afigr-12814.firebaseapp.com",
  projectId: "afigr-12814",
  storageBucket: "afigr-12814.appspot.com",
  messagingSenderId: "68796790734",
  appId: "1:68796790734:web:a77e112e6676342b7f1d9f",
  measurementId: "G-5097VG0NYX"
};

// Cloud Functions
const backendUrl = `https://europe-west3-${
  firebaseConfig.projectId
}.cloudfunctions.net/api`;

// App Engine
// const backendUrl = `<insert app engine url here>`;

export default {
  firebaseConfig,
  backendUrl,
};
