//
//
//
//
//
//

export default {
  props: ['src', 'direction', 'radius'],
};
