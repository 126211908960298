var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('header',{class:_vm.windowTop > 50 ? 'shadow' : null},[_c('div',{staticClass:"container"},[_c('router-link',{staticClass:"logo",attrs:{"to":"/"}},[_c('img',{attrs:{"src":"https://firebasestorage.googleapis.com/v0/b/afigr-12814.appspot.com/o/logo%20(8).webp?alt=media&token=c998704f-fd95-4061-bd08-a65dad1e9cef"}})]),_c('nav',[_vm._l((_vm.itemsScroll),function(item){return _c('router-link',{key:item.title,attrs:{"to":{
          name: 'home',
          hash: item.id,
          params: {
            lang: _vm.language,
          },
        }}},[_vm._v(" "+_vm._s(_vm.$t(item.title))+" ")])}),_vm._l((_vm.itemsLink),function(item){return _c('router-link',{key:item.title,attrs:{"to":{
          name: item.to,
          params: {
            lang: _vm.language,
          },
        }}},[_vm._v(" "+_vm._s(_vm.$t(item.title))+" ")])}),_c('I18nFlags')],2),_c('div',{staticClass:"menu",on:{"click":_vm.open}},[_c('Icon',{attrs:{"icon":"ci:menu-alt-02"}})],1)],1),_c('div',{staticClass:"drawer",class:_vm.drawer ? 'opened-Drawer' : null},[_c('div',{staticClass:"content-container",class:_vm.drawer ? 'opened-Drawer' : null},[_c('div',{staticClass:"header"},[_c('router-link',{staticClass:"logo",attrs:{"to":"/"}},[_c('img',{attrs:{"src":"https://firebasestorage.googleapis.com/v0/b/afigr-12814.appspot.com/o/logo%20(8).webp?alt=media&token=c998704f-fd95-4061-bd08-a65dad1e9cef"}})]),_c('div',{staticClass:"close",on:{"click":_vm.close}},[_c('Icon',{attrs:{"icon":"mingcute:close-line"}})],1)],1),_c('div',{staticClass:"content"},[_vm._l((_vm.itemsScroll),function(item){return _c('a',{key:item.title,attrs:{"href":item.id},on:{"click":_vm.close}},[_vm._v(" "+_vm._s(_vm.$t(item.title))+" ")])}),_vm._l((_vm.itemsLink),function(item){return _c('a',{key:item.title,attrs:{"href":item.to,"to":item.to},on:{"click":_vm.close}},[_vm._v(" "+_vm._s(_vm.$t(item.title))+" ")])}),_c('I18nFlags')],2)])])])}
var staticRenderFns = []

export { render, staticRenderFns }