//
//
//
//
//
//
//
//
//
//
//

import Footer from '../../footer/components/footer';
import myHeader from './header-new';

export default {
  name: 'app-layout',
  data() {
    return {
      icon: 'menu',
      closeIcon: 'x',
    };
  },
  components: {
    Footer,
    myHeader,
  },
};
