import Layout from '@/modules/layout/components/layout';
import {i18n} from '@/vueI18n'

const GalleryListPage = () =>
  import('@/modules/gallery/components/gallery-list-page.vue');

export default [
  {
    path: '',
    exact: true,
    component: Layout,
    children: [
      {
        name: 'gallery',
        path: '/:lang?/gallery',
        component: GalleryListPage,
        exact: true,
        beforeEnter: (to, from, next) => {
          document.title =
            i18n('app.title') +
            ' | ' +
            i18n('app.gallery') 
          next();
        },
      }
    ],
  },
];