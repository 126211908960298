import Vue from 'vue';
import Vuex from 'vuex';
import ProgressBar from '@/shared/progress-bar/progress-bar';
import Router from 'vue-router';
import shared from '@/shared/shared-module';
import auth from '@/modules/auth/auth-module';
import home from '@/modules/home/home-module';
import gallery from '@/modules/gallery/gallery-module';
import video from '@/modules/video/video-module';
import layout from '@/modules/layout/layout-module';
import partner from '@/modules/partner/partner-module';
import { vuexfireMutations } from 'vuexfire';
import i18n from '@/vueI18n';
const modules = {
  shared,
  home,
  gallery,
  auth,
  layout,
  partner,
  video,
};

// start - boilerplate code

const exists = (el) => !!el;

function setupComponentsFiltersDirectivesAndMixins() {
  Object.keys(modules)
    .map((key) => modules[key].components)
    .filter(exists)
    .forEach((components) => {
      components.forEach((component) => {
        Vue.component(component.name, component);
      });
    });

  Object.keys(modules)
    .map((key) => modules[key].filters)
    .filter(exists)
    .forEach((components) => {
      components.forEach((filter) => {
        Vue.filter(filter.name, filter.implementation);
      });
    });

  Object.keys(modules)
    .map((key) => modules[key].directives)
    .filter(exists)
    .forEach((directives) => {
      directives.forEach((directive) => {
        Vue.directive(
          directive.name,
          directive.implementation,
        );
      });
    });

  Object.keys(modules)
    .map((key) => modules[key].mixins)
    .filter(exists)
    .forEach((mixins) => {
      mixins.forEach((mixin) => {
        Vue.mixin(mixin);
      });
    });
}

const routes = [
  ...Object.keys(modules)
    .filter((key) => !!modules[key].routes)
    .map((key) => modules[key].routes)
    .reduce((a, b) => a.concat(b), []),
  { path: '*', redirect: `${i18n.locale}/404` },
];

let router = null;

const routerAsync = () => {
  if (!router) {
    router = new Router({
      mode: 'history',
      routes,
      scrollBehavior(to) {
        if (to.hash) {
          return {
            selector: to.hash,
          };
        }
      },
    });

    router.beforeEach((to, from, next) => {
      if (to.name) {
        ProgressBar.start();
      }
      next();
    });

    router.afterEach(() => {
      ProgressBar.done();
    });
  }

  return router;
};

const buildStores = () => {
  const output = {};

  Object.keys(modules)
    .filter((key) => !!modules[key].store)
    .forEach((key) => {
      output[key] = modules[key].store;
    });

  return output;
};

let store = null;

const storeAsync = () => {
  if (!store) {
    store = new Vuex.Store({
      modules: buildStores(),
      mutations: { ...vuexfireMutations },
    });
  }

  return store;
};

export {
  setupComponentsFiltersDirectivesAndMixins,
  routerAsync,
  storeAsync,
};

// end - boilerplate code
