import service from '@/modules/auth/auth-service';
import Errors from '@/shared/error/errors';
import ProgressBar from '@/shared/progress-bar/progress-bar';

export default {
  namespaced: true,

  state: {
    authenticationUser: null,
    currentUser: null,
    loadingInit: true,
    loadingEmailConfirmation: false,
    loadingPasswordResetEmail: false,
    loadingUpdateProfile: false,
    loading: false,
    loadingVerify: false,
    firstTime: true,
    confirmationResult: null,
  },

  getters: {
    confirmationResult: (state) => state.confirmationResult,
    authenticationUser: (state) => state.authenticationUser,
    currentUser: (state) => state.currentUser,
    currentUserEmail: (state, getters) =>
      getters.currentUser
        ? getters.currentUser.email
        : null,
    currentUserFullName: (state, getters) =>
      getters.currentUser
        ? getters.currentUser.fullName
        : '',

    signedIn: (state, getters) =>
      !!getters.currentUser && !!getters.currentUser.id,

    roles: (state, getters) =>
      getters.currentUser
        ? getters.currentUser.roles || []
        : [],

    completeRegistration: (state, getters) =>
      getters.currentUser
        ? !!getters.currentUser.phoneNumber &&
          !!getters.currentUser.idNumber &&
          !!getters.currentUser.userType &&
          !!getters.currentUser.userCategory  
        : false,

    emptyPermissions: (state, getters) =>
      !getters.roles || !getters.roles.length,

    loading: (state) => !!state.loading,
    loadingVerify: (state) => !!state.loadingVerify,
    firstTime: (state) => !!state.firstTime,
    loadingInit: (state) => !!state.loadingInit,

    loadingEmailConfirmation: (state) =>
      !!state.loadingEmailConfirmation,

    loadingPasswordResetEmail: (state) =>
      !!state.loadingPasswordResetEmail,

    loadingUpdateProfile: (state) =>
      !!state.loadingUpdateProfile,

    currentUserNameOrEmailPrefix: (state, getters) => {
      if (!getters.currentUser) {
        return null;
      }

      if (
        getters.currentUserFullName &&
        getters.currentUserFullName.length < 25
      ) {
        return getters.currentUserFullName;
      }

      if (getters.currentUser.firstName) {
        return getters.currentUser.firstName;
      }

      return getters.currentUser.email.split('@')[0];
    },

    currentUserAvatar: (state, getters) => {
      if (
        !getters.currentUser ||
        !getters.currentUser.avatars ||
        !getters.currentUser.avatars.length ||
        !getters.currentUser.avatars[0].publicUrl
      ) {
        return null;
      }

      return getters.currentUser.avatars[0].publicUrl;
    },
  },

  mutations: {
    CURRENT_USER_REFRESH_SUCCESS(state, payload) {
      state.currentUser = payload.currentUser || null;
    },
    VERIFY_START(state) {
      state.loadingVerify = true;
    },
    VERIFY_SUCCESS(state) {
      state.loadingVerify = false;
    },
    VERIFY_ERROR(state) {
      state.loadingVerify = false;
    },

    VERIFICATION_CODE_START(state) {
      state.loading = true;
    },
    VERIFICATION_CODE_SUCCESS(state, payload) {
      state.confirmationResult = payload.confirmationResult || null;
      state.loading = false;
    },
    VERIFICATION_CODE_ERROR(state) {
      state.confirmationResult = null;
      state.loading = false;
    },

    AUTH_START(state) {
      state.loading = true;
    },
    AUTH_SUCCESS(state, payload) {
      state.authenticationUser =
        payload.authenticationUser || null;
      state.currentUser = payload.currentUser || null;
      state.loading = false;
    },
    AUTH_ERROR(state) {
      state.authenticationUser = null;
      state.currentUser = null;
      state.loading = false;
    },

    EMAIL_CONFIRMATION_START(state) {
      state.loadingEmailConfirmation = true;
    },

    EMAIL_CONFIRMATION_SUCCESS(state) {
      state.loadingEmailConfirmation = false;
    },

    EMAIL_CONFIRMATION_ERROR(state) {
      state.loadingEmailConfirmation = false;
    },

    PASSWORD_RESET_START(state) {
      state.loadingPasswordResetEmail = true;
    },

    PASSWORD_RESET_SUCCESS(state) {
      state.loadingPasswordResetEmail = false;
    },

    PASSWORD_RESET_ERROR(state) {
      state.loadingPasswordResetEmail = false;
    },

    UPDATE_PROFILE_START(state) {
      state.loadingUpdateProfile = true;
    },

    UPDATE_PROFILE_SUCCESS(state) {
      state.loadingUpdateProfile = false;
    },

    UPDATE_PROFILE_ERROR(state) {
      state.loadingUpdateProfile = false;
    },

    AUTH_INIT_SUCCESS(state, payload) {
      state.authenticationUser =
        payload.authenticationUser || null;
      state.currentUser = payload.currentUser || null;
      state.loadingInit = false;
    },

    AUTH_INIT_ERROR(state) {
      state.authenticationUser = null;
      state.currentUser = null;
      state.loadingInit = false;
    },

    AUTH_NOT_FOUND_USER(state) {
      state.authenticationUser = null;
      state.currentUser = null;
      state.loadingInit = false;
    },

    STOP_SPLASH_SCREEN(state) {
      state.firstTime = false;
    },
  },

  actions: {
//#region [ Init ]

    async doInitFirebase() {
      await service.init();
    },
    async doInit({ commit, dispatch }) {
      // await service.init();
      let currentUser = localStorage.getItem('currentUser');
      if (!currentUser) {
        commit('AUTH_INIT_SUCCESS', {
          currentUser: null,
          authenticationUser: null,
        });
        ProgressBar.done();
        return;
      }
      
      const unsubscribe = service.onAuthStateChanged(
        (authenticationUser) => {
          dispatch('doSigninFromAuthChange', authenticationUser);
          unsubscribe();
        },
        () => {
          commit('AUTH_INIT_ERROR');
        },
      );
    },
    async doWaitUntilInit({ getters }) {
      if (!getters.loadingInit) {
        return Promise.resolve();
      }

      return new Promise((resolve) => {
        const waitUntilInitInterval = setInterval(() => {
          if (!getters.loadingInit) {
            clearInterval(waitUntilInitInterval);
            resolve();
          }
        }, 500);
      });
    },
//#endregion

    async doSigninFromAuthChange({ commit }, authenticationUser) {
      try {
        let currentUser = localStorage.getItem('currentUser');
        if (authenticationUser) {
          if (!currentUser) {
            currentUser = await service.fetchMe();
            localStorage.setItem(
              'currentUser',
              service.encryption(currentUser, 'secret-c-u'),
            );
          } else {
            currentUser = service.decryption(
              currentUser,
              'secret-c-u',
            );
          }

          // in background
          // service.reauthenticateWithStorageToken();
          currentUser.emailVerified = authenticationUser.emailVerified;
        }

        commit('AUTH_INIT_SUCCESS', {
          currentUser,
          authenticationUser: authenticationUser,
        });
        ProgressBar.done();
      } catch (error) {
        service.signout();
        Errors.handle(error);
        commit('AUTH_INIT_ERROR', error);
        ProgressBar.done();
      }
    },

    async doRefreshCurrentUser({ commit, getters }) {
      try {
        const authenticationUser = getters.authenticationUser;
        const currentUser = await service.fetchMe();
        currentUser.emailVerified = authenticationUser.emailVerified;
        
        //#region [ refresh CurrentUser in localStorage ]
        localStorage.setItem(
          'currentUser',
          service.encryption(currentUser, 'secret-c-u'),
        );
        //#endregion

        // in background
        service.reauthenticateWithStorageToken();

        commit('CURRENT_USER_REFRESH_SUCCESS', {
          currentUser,
        });
      } catch (error) {
        service.signout();
        Errors.handle(error);

        commit('CURRENT_USER_REFRESH_ERROR', error);
      }
    },

   
//#region [ Local Storage Functions ]
    doUpdateCurrentUserInLocalStorage( { commit }, currentUser) {
      debugger;
      if (currentUser) {
        localStorage.setItem(
          'currentUser',
          service.encryption(currentUser, 'secret-c-u'),
        );
        commit('CURRENT_USER_REFRESH_SUCCESS', {
          currentUser,
        });
      }
    },
//#endregion
  },
};
