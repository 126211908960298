import Layout from '@/modules/layout/components/layout';
import {i18n} from '@/vueI18n'

const VideoListPage = () =>
  import('@/modules/video/components/video-list-page.vue');

export default [
  {
    path: '',
    exact: true,
    component: Layout,
    children: [
      {
        name: 'videos',
        path: '/:lang?/videos',
        component: VideoListPage,
        exact: true,
        beforeEnter: (to, from, next) => {
          document.title =
            i18n('app.title') +
            ' | ' +
            i18n('app.videos') 
          next();
        },
      }
    ],
  },
];