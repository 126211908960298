//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { scroll } from 'quasar';
const { getScrollTarget, setScrollPosition } = scroll;
import feather from 'feather-icons';
import I18nFlags from '../../../shared/i18n/i18n-flags.vue';
import i18n from '@/vueI18n';

export default {
  name: 'app-header',
  components: {
    [I18nFlags.name]: I18nFlags,
  },
  data: () => ({
    drawer: false,
    itemsScroll: [
      // { title: 'header.home', id: 'home' },
      { title: 'header.about', id: 'about' },
      { title: 'header.ourCompany', id: 'owner' },
      { title: 'header.contact', id: 'contact' },
    ],
    itemsLink: [
      { title: 'header.gallery', to: '/gallery' },
      { title: 'header.videos', to: '/videos' },
    ],
    icon: 'menu',
    closeIcon: 'x',
  }),

  computed: {
    language() {
      return i18n.locale;
    },
  },

  methods: {
    iconSvg(icon) {
      return feather.icons[icon].toSvg();
    },
    async checkRoute(eleId) {
      // this.drawer=false;
      const lang = localStorage.getItem('language');
      if (
        !(
          this.$router.currentRoute.path == `/${lang}/` ||
          this.$router.currentRoute.path == `/${lang}`
        )
      ) {
        await this.$router.push(`/${lang}/`);
        this.handleScroll(eleId);
      } else {
        this.handleScroll(eleId);
      }
    },

    navigate(link) {
      this.drawer = false;
      const lang = localStorage.getItem('language');
      this.$router.push(`/${lang}${link}`);
    },

    handleScroll(eleId) {
      var offset;
      const ele = document.getElementById(eleId);
      var target = getScrollTarget(ele);
      var duration = 500;
      if (eleId == 'home') {
        offset = 0;
      } else {
        offset = ele.offsetTop;
      }
      setScrollPosition(target, offset, duration);
    },
    i18n(key, args) {
      return this.$t(key, args);
    },
  },
};
