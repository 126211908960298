//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapActions } from 'vuex';
import i18n from '@/vueI18n';
import firebase from 'firebase/app';
import 'firebase/auth';

export default {
  name: 'app',

  // created() {
  //   this.doInit();
  //   window.addEventListener('resize', this.handleResize);
  //   this.handleResize();
  // },
  async created() {
    const promises = [
      new Promise(() => this.doInitFirebase()),
      new Promise(() => this.doInit()),
    ];
    Promise.all(promises)
      .then
      // console.log('doInitFirebase Done & doInit Done')
      ();

    window.addEventListener('resize', this.handleResize);
    this.handleResize();
    if (!this.anonymousToken) {
      const firebaseUser = await firebase
        .auth()
        .signInAnonymously();
      this.anonymousToken = firebaseUser;
    }
  },
  mounted() {
    if (localStorage.getItem('language') == 'ar') {
      // console.log(document.getElementById('app'));
      import(`quasar/lang/ar`).then((lang) => {
        this.$q.lang.set(lang.default);
      });
    } else {
      // console.log(document.getElementById('app'));
      import(`quasar/lang/en-us`).then((lang) => {
        this.$q.lang.set(lang.default);
      });
    }
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize);
  },

  methods: {
    ...mapActions({
      doInitFirebase: 'auth/doInitFirebase',
      doInit: 'auth/doInit',
      resize: 'layout/resize',
    }),

    handleResize() {
      this.resize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    },
  },
  data() {
    return {
      anonymousToken: null,
    };
  },
  computed: {
    ...mapGetters({
      loadingInit: 'auth/loadingInit',
    }),
    lang() {
      return i18n.locale;
    },
  },
  watch: {
    lang(newVal) {
      // console.log('lang old value', oldVal);
      // console.log('lang new value', newVal);
      if (newVal == 'ar') {
        // document
        //   .getElementsByTagName('html')[0]
        //   .setAttribute('dir', 'rtl');
        // this.$q.lang.rtl = true;
      }
      if (newVal == 'en') {
        // document
        //   .getElementsByTagName('html')[0]
        //   .setAttribute('dir', 'ltr');
        // this.$q.lang.rtl = false;
      }
    },
  },
};
