import firebase from 'firebase/app';
import 'firebase/firestore';

export default {
  namespaced: true,

  state: {
      loading:false,
  },

  getters: {
    loading: (state) => state.loading,
  },

  mutations: {
    CREATE_STARTED(state){
      console.log('load');
        state.loading=true
    },
    CREATE_FINISHED(state){
      console.log('finish');
      state.loading=false
  }
},

  actions: {

    async doSubmitContactForm_SamaElBaraka ({ commit }, data) {
        commit('CREATE_STARTED')
        var id = firebase
          .firestore()
          .collection('ids')
          .doc().id;
        console.log(commit);
        console.log(data);
        var obj = {
          id: id,
          firstName: data.firstName,
          lastName: data.lastName,
          email: data.email,
          phoneNumber: data.phoneNumber,
          service: data.service,
          branch: data.branch,
          message: data.message,
          createdAt: firebase.firestore.Timestamp.now(),
        };
        await firebase
          .firestore()
          .collection('contactUs-SamaElBaraka')
          .doc(id)
          .set({
            ...obj,
          });
        if (data.language == 'ar') {
          firebase
            .firestore()
            .collection('mail')
            .doc(id)
            .set({
              to: data.email,
              template: {
                name: 'contactEmailToClient-SamaElBaraka-ar',
              },
            });
        } else {
          firebase
            .firestore()
            .collection('mail')
            .doc(id)
            .set({
              to: data.email,
              template: {
                name: 'contactEmailToClient-SamaElBaraka',
              },
            });
        }

        id = firebase
          .firestore()
          .collection('ids')
          .doc().id;
        firebase
          .firestore()
          .collection('mail')
          .doc(id)
          .set({
            to: 'chairman@afigr.com',
            template: {
              name: 'contactEmailToCompany-SamaElBaraka',
              data: {
                name: data.firstName + " " + data.lastName,
                phoneNumber: data.phoneNumber,
                email: data.email,
                branch:data.branch,
                service:data.service,
                message: data.message,
              },
            },
          });

        commit('CREATE_FINISHED')
      },
    
  },
};
