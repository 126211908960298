import '@/shared/plugins/element';
import Vue from 'vue';
import Vuex from 'vuex';
import Router from 'vue-router';
import {
  setupComponentsFiltersDirectivesAndMixins,
  storeAsync,
  routerAsync,
} from '@/app-module';
import app from '@/app.vue';
// import { SettingsService } from '@/modules/settings/settings-service';
import ProgressBar from '@/shared/progress-bar/progress-bar';
import PortalVue from 'portal-vue';
import './quasar';
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css';
import '../src/assets/scss/main.scss';
import VueParticles from 'vue-particles';
import CoolLightBox from 'vue-cool-lightbox';
import VueScrollactive from 'vue-scrollactive';
import 'vue-slick-carousel/dist/vue-slick-carousel.css';
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css';
import '@fortawesome/fontawesome-free/css/all.css';
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css';
import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';

import mainImageCard from '@/shared/components/main-image-card';
import mainSection from '@/shared/components/main-section';

// import vuetifyCountryRegionSelect from "vuetify-country-region-select"
import Chat from 'vue-beautiful-chat';
import LoadScript from 'vue-plugin-load-script';
import './quasar';
import i18n from './vueI18n';
import Notifications from 'vue-notification';
import VueCarousel from 'vue-carousel';
Vue.use(VueCarousel);

Vue.use(Notifications);
Vue.component(
  'vue-phone-number-input',
  VuePhoneNumberInput,
);
Vue.component('mainImageCard', mainImageCard);
Vue.component('mainSection', mainSection);

(async function() {
  ProgressBar.start();
  // SettingsService.fetchAndApply();
  Vue.use(LoadScript);
  Vue.use(Vuetify);
  // Vue.use(vuetifyCountryRegionSelect)
  Vue.use(Chat);
  Vue.use(Router);
  Vue.use(VueParticles);
  Vue.use(CoolLightBox);
  Vue.use(VueScrollactive);
  Vue.use(PortalVue);
  Vue.config.productionTip =
    process.env.NODE_ENV === 'production';
  Vue.use(Vuex);
  setupComponentsFiltersDirectivesAndMixins();

  // ======================================= New Installer ======================================= //
  //#region [ New Installer ]

  //#endregion
  // ============================================================================================= //

  // eslint-disable-next-line
  new Vue({
    //     components: {
    //   "vue-country-select": require("vue-country-select")
    // },
    store: storeAsync(),
    router: routerAsync(),
    render: (h) => h(app),
    vuetify: new Vuetify(),
    i18n,
  }).$mount('#app');
})();
